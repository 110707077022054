import React, { useEffect, useState } from "react";
import Select from "react-select";
import PopUp from "../../layout/PopUp";

const closeIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.00072 5.58672L11.9507 0.636719L13.3647 2.05072L8.41472 7.00072L13.3647 11.9507L11.9507 13.3647L7.00072 8.41472L2.05072 13.3647L0.636719 11.9507L5.58672 7.00072L0.636719 2.05072L2.05072 0.636719L7.00072 5.58672Z"
      fill="#021D49"
    />
  </svg>
);

const filterIcon = (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.66667 8H7.33333V6.66667H4.66667V8ZM0 0V1.33333H12V0H0ZM2 4.66667H10V3.33333H2V4.66667Z"
      fill="#DB3D91"
    />
  </svg>
);

const FilterSort = ({ categories, authors, statuses, tags, types, params }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [filterPath, setFilterPath] = useState(location.pathname);

  const setDefaultValue = (value, labels) => {
    if (!value) return null;

    const option = labels.find((i) => i[0] == value);

    return { value: option[0], label: option[1] };
  };

  const formatValues = (values, labels) =>
    values ? values.split(",").map((v) => setDefaultValue(v, labels)) : null;

  const formatParams = (param) => param.map((v) => v.value).join(",");

  const [order, setOrder] = useState(
    setDefaultValue(params["order"], [
      ["ASC", "Más antiguos"],
      ["DESC", "Más recientes"],
    ])
  );

  const [category, setCategory] = useState(
    formatValues(params["category"], categories)
  );
  const [status, setStatus] = useState(
    formatValues(params["status"], statuses)
  );
  const [author, setAuthor] = useState(formatValues(params["author"], authors));
  const [type, setType] = useState(formatValues(params["type"], types));
  const [selectedTags, setSelectedTags] = useState(
    formatValues(params["tags"], tags)
  );

  const handleClosePopUp = () => setShowPopup(false);

  useEffect(() => {
    // Set not null filters
    const filters = {
      ...(params["page"] && { page: params["page"] }),
      ...(order && { order: order.value }),
      ...(category && { category: formatParams(category) }),
      ...(status && { status: formatParams(status) }),
      ...(author && { author: formatParams(author) }),
      ...(type && { type: formatParams(type) }),
      ...(selectedTags && { tags: formatParams(selectedTags) }),
    };

    const filterParams = new URLSearchParams(filters).toString();

    setFilterPath(`?${filterParams}`);
  }, [order, category, status, author, type, selectedTags]);

  return (
    <>
      <button
        className="filter-btn"
        type="button"
        onClick={() => setShowPopup(true)}
      >
        {filterIcon}
        Filtrar y organizar
      </button>

      {showPopup && (
        <PopUp closeAction={handleClosePopUp}>
          <div className="popup__header border-0 justify-content-between">
            <h4>Filtrar por:</h4>
            <div className="popup__header__close">
              <button
                className="popup__header__close__button"
                onClick={handleClosePopUp}
              >
                {closeIcon}
              </button>
            </div>
          </div>
          <div className="text-start popup__body row">
            <div className="col-6">
              <label className="form-label">Fecha</label>
              <Select
                isClearable
                value={order}
                onChange={setOrder}
                placeholder="Más recientes"
                options={[
                  { value: "DESC", label: "Más recientes" },
                  { value: "ASC", label: "Más antiguos" },
                ]}
              />
            </div>
            <div className="col-6">
              <label className="form-label">Categoría</label>
              <Select
                isClearable
                isMulti
                value={category}
                onChange={setCategory}
                placeholder="Todos las categorías"
                options={categories.map((i) => ({ value: i[0], label: i[1] }))}
              />
            </div>
            <div className="col-6 mt-3">
              <label className="form-label">Autor</label>
              <Select
                isClearable
                isMulti
                value={author}
                onChange={setAuthor}
                placeholder="Todos los autores"
                options={authors.map((i) => ({ value: i[0], label: i[1] }))}
              />
            </div>
            <div className="col-6 mt-3">
              <label className="form-label">Status</label>
              <Select
                isClearable
                isMulti
                value={status}
                onChange={setStatus}
                placeholder="Todas los status"
                options={statuses.map((i) => ({ value: i[0], label: i[1] }))}
              />
            </div>
            <div className="col-6 mt-3">
              <label className="form-label">Tipo de artículo</label>
              <Select
                isClearable
                isMulti
                value={type}
                onChange={setType}
                placeholder="Todos los tipos"
                options={types.map((i) => ({ value: i[0], label: i[1] }))}
              />
            </div>
            <div className="col-6 mt-3">
              <label className="form-label">Buscar por tags</label>
              <Select
                isClearable
                isMulti
                value={selectedTags}
                onChange={setSelectedTags}
                placeholder="Buscar tags"
                options={tags.map((i) => ({ value: i[0], label: i[1] }))}
              />
            </div>
          </div>
          <div className="popup__footer d-flex justify-content-end">
            <a
              href={location.pathname}
              className="btn btn-return"
              onClick={handleClosePopUp}
            >
              Cancelar
            </a>
            <a
              href={filterPath}
              className="btn btn-publish mx-3 w-auto h-auto m-0"
            >
              Aplicar
            </a>
          </div>
        </PopUp>
      )}
    </>
  );
};

export default FilterSort;
