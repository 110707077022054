import React, { useState } from "react";

const loadingSpinner = (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ margin: 'auto', display: 'block' }} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
    <path d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#DB3D91" stroke="none">
      <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 51;360 50 51"></animateTransform>
    </path>
  </svg>
);

const postToStream = async (path, body) => {
  try {
    const response = await fetch(path, {
      method: "POST",
      body: body
    });

    return await response.json();
  } catch (error) {
    return [];
  }
};

const ToggleStream = ({ path, id, token }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    let body;
    
    if (id) {
      body = new FormData(document.getElementById(id));
    };
    
    if (token) {
      body = new FormData();
      body.append('authenticity_token', token);
    };
    
    setIsLoading(true);

    const response = await postToStream(path, body);

    if (response.url) {
      window.location = response.url;
    }
  };

  return (
    <>
      {isLoading && <div className="loading-spinner">{loadingSpinner}</div>}
      <button
        disabled={isLoading}
        type="button"
        className="btn btn-publish"
        onClick={handleClick}
      >
        {id ? 'Iniciar Stream' : 'Detener Stream'}
      </button>
    </>
  );
};

export default ToggleStream;
