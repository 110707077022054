import React, { createContext, useState, useLayoutEffect  } from 'react'
import ArticleType from './ArticleType';
import ArticleForm from './ArticleForm';
import { CreateArticleContext } from '../../contexts/CreateArticleContext';

const CreateArticle = ({ pathNames, statuses, tags, authenticityToken, categories, editions, currentArticle, articleTypes, icons }) => {
  const [articleType, setArticleType] = useState(null);

  const removeArticleCard = () => {
    setArticleType(null);
  }

  useLayoutEffect(() => {
    if (currentArticle) {
      setArticleType(articleTypes.find((itemType) => itemType.id === currentArticle.articleTypeId));
    }
  }, [])
  

  return (
    <CreateArticleContext.Provider value={{ pathNames, currentArticle, icons }}>
      {
        !articleType ? 
          <ArticleType
            setArticleType={ setArticleType }
            articleTypes={ articleTypes }
          />
          :
          <ArticleForm
            articleType={ articleType }
            removeArticleCard={ removeArticleCard }
            statuses={ statuses }
            tags={ tags }
            categories={ categories }
            editions={ editions }
            authenticityToken={ authenticityToken }
          />
      }
    </CreateArticleContext.Provider>
  )
}

CreateArticle.propTypes = {}

export default CreateArticle