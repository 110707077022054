import React, { useState, useEffect } from "react";
import EventSlideshowCard from "../components/EventSlideshowCard";
import { ReactSortable } from "react-sortablejs";

const EventsSlideshow = ({ events, authenticityToken }) => {
  const [cards, setCards] = useState(events);
  const [showSave, setShowSave] = useState(false);

  const saveOrder = async () => {
    const params = new FormData();
    params.append("order_ids", JSON.stringify(cards.map((i) => i.id)));
    params.append("category", 'event.slides');
    params.append("authenticity_token", authenticityToken);

    const postPath = "save_sorting";

    await fetch(postPath, {
      method: "POST",
      body: params,
    });

    setShowSave(false);
  };

  return (
    <div className="event-slideshow__container">
      {showSave && (
        <button className="btn btn-publish order-btn" onClick={saveOrder}>
          Guardar Orden
        </button>
      )}
      {events.length > 0 ? (
        <ReactSortable
          list={cards}
          setList={setCards}
          className="event-slideshow__cards"
          onEnd={() => setShowSave(true)}
        >
          {cards.map((event) => (
            <EventSlideshowCard event={event} key={event.url} />
          ))}
        </ReactSortable>
      ) : (
        <div className="event-slideshow__empty">
          <h2>No se ha agregado ningún evento</h2>
        </div>
      )}
    </div>
  );
};

export default EventsSlideshow;
