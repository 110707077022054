import ReactOnRails from 'react-on-rails';
import CallToAction from '../bundles/components/CallToAction';
import Conekta from '../bundles/components/Conekta';
import GoogleMapsApi from '../bundles/components/GoogleMapsApi';
import GoogleMapsOne from '../bundles/components/GoogleMapsOne';
import PopUpDelete from '../bundles/components/PopUpDelete';
import PopUpEventSliceshowForm from '../bundles/components/PopUpEventSliceshowForm';
import PopUpNewFilter from '../bundles/components/PopUpNewFilter';
import PopUpNewHeroButton from '../bundles/components/PopUpNewHeroButton';
import PopUpNewSpecificFilter from '../bundles/components/PopUpNewSpecificFilter';
import PreviewImage from '../bundles/components/PreviewImage';
import PreviewVideo from '../bundles/components/PreviewVideo';
import PriceRange from '../bundles/components/PriceRange';
import ProfilePicture from '../bundles/components/ProfilePicture';
import SearchBar from '../bundles/components/SearchBar';
import SearchBarUrl from '../bundles/components/SearchBarUrl';
import ShowConektaModal from '../bundles/components/ShowConektaModal';
import Sidebar from '../bundles/components/Sidebar';
import StickyInfo from '../bundles/components/StickyInfo';
import Synonyms from '../bundles/components/Synonyms';
import UserOptions from '../bundles/components/UserOptions';
import SearchSelect from '../bundles/components/SearchSelect';
import Events from '../bundles/events/Events';
import EventsPrice from '../bundles/events/form/EventsPrice';
import EventsSalesSchedule from '../bundles/events/form/EventsSalesSchedule';
import EventsSchedule from '../bundles/events/form/EventsSchedule';
import EventsTickets from '../bundles/events/form/EventsTickets';
import Banners from '../bundles/form/components/Banners';
import CategorySubcategory from '../bundles/form/components/CategorySubcategory';
import DateTimeSchedule from '../bundles/form/components/DateTimeSchedule';
import DiscountFields from '../bundles/form/components/DiscountFields';
import InputSuggest from '../bundles/form/components/InputSuggest';
import MenuServiceCategory from '../bundles/form/components/MenuServiceCategory';
import PasswordChange from '../bundles/form/components/PasswordChange';
import SocialMediaCard from '../bundles/form/components/SocialMediaCard';
import TimeSchedule from '../bundles/form/components/TimeSchedule';
import UserSuggest from '../bundles/form/components/UserSuggest';
import DropZone from '../bundles/slideshow/components/DropZone';
import EventsSlideshow from '../bundles/slideshow/containers/EventsSlideshow';
import Slideshow from '../bundles/slideshow/containers/Slideshow';
import ArticleTable from '../bundles/todos/components/ArticleTable';
import TopFour from '../bundles/todos/components/TopFour';
import Display from '../bundles/todos/components/Display';

// Grada components
import ArticleFilter from '../bundles/components/filters/ArticleFilter';
import ArticleType from '../bundles/components/articles/ArticleType';
import CreateArticle from '../bundles/components/articles/CreateArticle';
import OttVideoFilter from '../bundles/components/filters/OttVideoFilter';
import OttVideosForm from '../bundles/components/videos/OttVideosForm';
import VideoPlayer from '../bundles/components/videos/VideoPlayer';
import ListSort from '../bundles/components/listings/ListSort';
import ToggleStream from '../bundles/components/videos/ToggleStream';
import FilterSort from '../bundles/components/filters/FilterSort';
import TagAppend from '../bundles/components/TagAppend';
import RichText from '../bundles/form/components/RichText';

// Paylists
import CreatePlaylist from '../bundles/components/playlists/CreatePlaylist';

// This is how rails can see and use components
// We need to "register" all components in here
ReactOnRails.register({
  ArticleTable,
  ArticleType,
  ArticleFilter,
  Banners,
  CallToAction,
  CategorySubcategory,
  CreateArticle,
  Conekta,
  DateTimeSchedule,
  DiscountFields,
  TopFour,
  Display,
  DropZone,
  Events,
  EventsPrice,
  EventsSalesSchedule,
  EventsSchedule,
  EventsSlideshow,
  CreatePlaylist,
  EventsTickets,
  FilterSort,
  InputSuggest,
  GoogleMapsApi,
  GoogleMapsOne,
  MenuServiceCategory,
  ListSort,
  OttVideosForm,
  OttVideoFilter,
  PasswordChange,
  PreviewImage,
  PreviewVideo,
  ProfilePicture,
  PopUpDelete,
  PopUpEventSliceshowForm,
  PopUpNewFilter,
  PopUpNewHeroButton,
  PopUpNewSpecificFilter,
  PriceRange,
  RichText,
  SearchSelect,
  SearchBar,
  Sidebar,
  SearchBarUrl,
  Synonyms,
  Slideshow,
  SocialMediaCard,
  ShowConektaModal,
  TagAppend,
  TimeSchedule,
  UserOptions,
  UserSuggest,
  VideoPlayer,
  StickyInfo,
  ToggleStream,
});
