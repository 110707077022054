import React, { useState } from 'react';
import PropTypes from 'prop-types';

const priceIcon = (
<svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M23 45.5C10.5733 45.5 0.5 35.4268 0.5 23C0.5 10.5733 10.5733 0.5 23 0.5C35.4268 0.5 45.5 10.5733 45.5 23C45.5 35.4268 35.4268 45.5 23 45.5ZM15.125 27.5V32H20.75V36.5H25.25V32H27.5C28.9918 32 30.4226 31.4074 31.4775 30.3525C32.5324 29.2976 33.125 27.8668 33.125 26.375C33.125 24.8832 32.5324 23.4524 31.4775 22.3975C30.4226 21.3426 28.9918 20.75 27.5 20.75H18.5C18.2016 20.75 17.9155 20.6315 17.7045 20.4205C17.4935 20.2095 17.375 19.9234 17.375 19.625C17.375 19.3266 17.4935 19.0405 17.7045 18.8295C17.9155 18.6185 18.2016 18.5 18.5 18.5H30.875V14H25.25V9.5H20.75V14H18.5C17.0082 14 15.5774 14.5926 14.5225 15.6475C13.4676 16.7024 12.875 18.1332 12.875 19.625C12.875 21.1168 13.4676 22.5476 14.5225 23.6025C15.5774 24.6574 17.0082 25.25 18.5 25.25H27.5C27.7984 25.25 28.0845 25.3685 28.2955 25.5795C28.5065 25.7905 28.625 26.0766 28.625 26.375C28.625 26.6734 28.5065 26.9595 28.2955 27.1705C28.0845 27.3815 27.7984 27.5 27.5 27.5H15.125Z" fill="#DB3D91"/>
</svg>
);

const PriceRange = ({ currentPrice }) => {
  const [price, setPrice] = useState(currentPrice ?? 1);

  const handlePricing = (e) => {
    const { value } = e.target;

    setPrice(value);
  };

  return (
    <div className="price-range">
      {[1, 2, 3, 4].map((tier) => (
        <label className={price >= tier ? '' : '--deselect'} key={tier}>
          <input
            type="radio"
            name="commerce[price]"
            value={tier}
            onClick={(e) => handlePricing(e)}
          />
          { priceIcon }
        </label>
      ))}

      <p>Posición {price} de 4 actualmente</p>

    </div>
  );
};

PriceRange.propTypes = {
  currentPrice: PropTypes.number,
}

PriceRange.defaultProps = {
  currentPrice: 1,
}

export default PriceRange;
