import React, { useState } from "react";
import DropZone from "../../slideshow/components/DropZone";
import { useEffect } from "react";

const CarruselForm = ({ showCarrusel, images }) => {
  const [showCarruselContent, setShowCarruselContent] = useState(false);

  useEffect(() => {
    setShowCarruselContent(images.length > 0)
  }, [images])

  return (
    <>
      {showCarrusel && (
        <div
          className="score-box__drop-area"
          onDragOver={(e) => {
            e.preventDefault();
          }}
          onDrop={() => setShowCarruselContent(true)}
        >
          <p>Agregar carrusel</p>
        </div>
      )}
      {showCarruselContent && (
        <div
          className='score-box__body'
        >
          <p className='h5 score-box__title'>CARRUSEL</p>
          <DropZone images={images} resource="article[slides]" />
        </div>
      )}
    </>
  );
};

export default CarruselForm;
