import React, { useState } from "react";
import ArticleCard from "./ArticleCard";
import { useEffect } from "react";

const DisplayPreview = React.memo(
  ({ urls, authenticityToken, cardOptions, size, pending }) => {
    const [newCardOptions, setNewCardOptions] = useState(cardOptions);

    const removeArticleCard = (order) => {
      setNewCardOptions(newCardOptions.filter((item) => item.order != order));
    };

    const addArticleCard = (order, article) => {
      if (newCardOptions.find((item) => item.order == order)) {
        let newList = [...newCardOptions];

        if (newList.find((item) => item.article_id == article.id)) {
          newList.find((item) => item.order == order).order = newList.find(
            (item) => item.article_id == article.id
          ).order;
          newList = newList.filter(
            (item) => parseInt(item.article_id) != parseInt(article.id)
          );
        } else {
          newList = newList.filter(
            (item) => parseInt(item.order) != parseInt(order)
          );
        }

        setNewCardOptions([
          ...newList,
          {
            order: order,
            article_id: article.id,
            article: article,
          },
        ]);
      } else {
        setNewCardOptions([
          ...newCardOptions,
          {
            order: order,
            article_id: article.id,
            article: article,
          },
        ]);
      }
    };

    const handleDropArticle = (e, order) => {
      e.preventDefault();
      const article = JSON.parse(e.dataTransfer.getData("article"));
      addArticleCard(order, article);
    };

    useEffect(() => {
      setNewCardOptions(cardOptions);
    }, [cardOptions]);

    return (
      <>
        <div className="todo-home__display__preview">
          <h2 className="todo-home__display__types__title">Vista previa</h2>
          <p className="todo-home__display__types__description">
            Arrastra el articulo hacia la posición donde quieras mostrarlo
          </p>
          <div className="todo-home__display__preview__area --topfive-area">
            {[...Array(size).keys()].map((i) => {
              const ord = i + 1;
              const card = newCardOptions.find((a) => a.order == ord);

              return (
                <div
                  className={card ? "todo-card-area" : "add-article"}
                  onDragOver={(e) => {
                    e.preventDefault();
                  }}
                  onDrop={(e) => handleDropArticle(e, ord)}
                >
                  {card ? (
                    <ArticleCard
                      {...card.article}
                      articleArea={ord}
                      removeArticleCard={removeArticleCard}
                    />
                  ) : (
                    <div className="add-icon" />
                  )}

                  <div className="card-number">{ord}</div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="d-flex justify-content-between mt-4">
          <a className="btn btn-return mx-2" href={urls.back}>
            Volver
          </a>
          <form method="post" action={urls.form}>
            <input
              type="hidden"
              name="authenticity_token"
              value={authenticityToken}
            />
            <input
              type="hidden"
              name="articles"
              value={JSON.stringify(newCardOptions)}
            />
            <input
              type="hidden"
              name="top-four-status"
              value={pending ? "pending" : "published"}
            />
            <button className="btn btn-publish">Confirmar</button>
          </form>
        </div>
      </>
    );
  }
);

export default DisplayPreview;
