import React from "react";
import PropTypes from "prop-types";

const deleteIcon = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.0006 4.82166L10.1256 0.696655L11.3039 1.87499L7.17893 5.99999L11.3039 10.125L10.1256 11.3033L6.0006 7.17832L1.8756 11.3033L0.697266 10.125L4.82227 5.99999L0.697266 1.87499L1.8756 0.696655L6.0006 4.82166Z"
      fill="white"
    />
  </svg>
);

const ArticleCard = React.memo(
  ({
    title,
    description,
    date,
    setShowPopUp,
    articleArea,
    removeArticleCard,
    id,
  }) => {
    const handleRemoveCardArticle = (order) => {
      removeArticleCard(order);
      setShowPopUp({
        status: false,
        order: null,
      });
    };

    const handleDragArticle= (e) => {    
      e.dataTransfer.setData('article', JSON.stringify({
        title, description, date, category, id
      }));
    }

    return (
      <div className="todo-card" draggable onDragStart={handleDragArticle}>
        <div className="todo-card__content">
          <div className="todo-card__content__buttons">
          </div>
          <h3 className="todo-card__content__title">{title}</h3>
        </div>
        <div className="todo-card__hover">
          <div className="todo-card__hover__options">
            <button
              className="todo-card__hover__options__option --delete"
              onClick={() => handleRemoveCardArticle(articleArea)}
            >
              {deleteIcon}
            </button>
          </div>
        </div>
      </div>
    );
  }
);

ArticleCard.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  comments: PropTypes.number,
  description: PropTypes.string,
  category: PropTypes.string,
  popular: PropTypes.bool,
  setShowPopUp: PropTypes.func,
  articleArea: PropTypes.number,
  removeArticleCard: PropTypes.func,
};

export default ArticleCard;
