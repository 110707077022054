import React from "react";
import PopUpDelete from "../../components/PopUpDelete";

const pencilIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11.1067 6.07161L9.92833 4.89328L2.16667 12.6549V13.8333H3.345L11.1067 6.07161ZM12.285 4.89328L13.4633 3.71495L12.285 2.53661L11.1067 3.71495L12.285 4.89328ZM4.035 15.4999H0.5V11.9641L11.6958 0.768281C11.8521 0.612055 12.064 0.524292 12.285 0.524292C12.506 0.524292 12.7179 0.612055 12.8742 0.768281L15.2317 3.12578C15.3879 3.28205 15.4757 3.49398 15.4757 3.71495C15.4757 3.93592 15.3879 4.14784 15.2317 4.30411L4.03583 15.4999H4.035Z" fill="#FCFCFC"/>
  </svg>
);

const deleteButton = `
<button class="btn btn-danger">
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.0006 4.82166L10.1256 0.696655L11.3039 1.87499L7.17893 5.99999L11.3039 10.125L10.1256 11.3033L6.0006 7.17832L1.8756 11.3033L0.697266 10.125L4.82227 5.99999L0.697266 1.87499L1.8756 0.696655L6.0006 4.82166Z" fill="white"/>
  </svg>
</button>`;

const EventSlideshowCard = ({ event }) => {
  return (
    <div className="slideshow-card">
      <img className="slideshow-card__image" src={event.railsInputs.image} />
      <div className="slideshow-card__options">
        <a href={event.eventUrl} className="btn btn-warning">{pencilIcon}</a>
        <PopUpDelete
          button={deleteButton}
          text="¿Estas seguro de querer eliminar la portada?"
          url={event.url}
        />
      </div>
    </div>
  );
};

export default EventSlideshowCard;
