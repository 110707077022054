import React, { useContext, useEffect, useRef, useState } from 'react';
import PreviewImageImprove from '../PreviewImageImprove';
import ArticleFormTools from './ArticleFormTools';
import { CreateArticleContext } from '../../contexts/CreateArticleContext';
import { Editor } from '@tinymce/tinymce-react';
import PopUpDelete from '../PopUpDelete';
import CarruselForm from './CarruselForm';
import PreviewVideo from '../PreviewVideo';

const ArticleForm = ({ articleType, removeArticleCard, statuses, tags:railsTags, categories:railsCategories, editions:railsEditions, authenticityToken }) => {
  const [showCarruselDrop, setShowCarruselDrop] = useState(false);

  const formRef = useRef();

  // Offset in milliseconds
  var tzoffset = (new Date()).getTimezoneOffset() * 60000;
  
  // Deafault time for publish date
  const currentTime = new Date(Date.now() - tzoffset);
  currentTime.setMilliseconds(null);
  currentTime.setSeconds(null);
  
  const [articleParams, setArticleParams] = useState({
    id: '',
    user_id: '',
    title: '',
    description: '',
    img: '',
    video: '',
    imgDescription: '',
    content: '',
    publishDate: currentTime.toISOString().slice(0, -1),
    status: '',
    articleType: articleType,
    category_id: articleType.name === "Opinión" ? railsCategories.find(ctgy => ctgy.name === "Opinión")?.id : '',
    scoreBox: null,
    slug: '',
    slideImages: [],
    categories: [],
    editions: [],
    tags: [],
  });
  const tagFormat = (tagList) => tagList.map(tag => ({value: tag.id, label: tag.name}));

  const tags = tagFormat(railsTags);
  const categories = tagFormat(railsCategories);
  const editions = tagFormat(railsEditions);
  
  const editorRef = useRef();
  const imageRef = useRef();
  const videoRef = useRef();

  const [allowSave, setAllowSave] = useState();
  
  useEffect(() => {
    const { publishDate, status, categories } = articleParams;
    
    if (
      [
        publishDate != "", 
        status != "Elegir status", 
        status != "", 
        categories.length != 0,
      ].every((i) => i)
    ) {
      setAllowSave(true);
    } else {
      setAllowSave(false);
    }
  }, [articleParams])
  
  const { pathNames, currentArticle } = useContext(CreateArticleContext);

  const handleEditForm = ( name, value ) => {
    if (!articleParams.hasOwnProperty(name)) {
      return;
    }

    setArticleParams(
      {
        ...articleParams,
        [name]: value
      }
    );
  };

  const editImage = (target) => {
    if (target.files) {
      imageRef.current.files = target.files;
    }
  }

  const editVideo = (target) => {
    if (target.files) {
      videoRef.current.files = target.files;
    }
  }

  useEffect(() => {
    if (currentArticle) {
      setArticleParams({
        id: currentArticle.id,
        user_id: currentArticle.author,
        title: currentArticle.title,
        description: currentArticle.description,
        img: currentArticle.img,
        video: currentArticle.video,
        imgDescription: currentArticle.imgDescription,
        content: currentArticle.content,
        publishDate: currentArticle.publishDate.slice(0, -6),
        status: currentArticle.status,
        articleType: articleType,
        slug: currentArticle.slug,
        category_id: currentArticle.category_id,
        categories: tagFormat(railsCategories.filter(({id}) => (currentArticle.categories.includes(id)))),
        editions: tagFormat(railsEditions.filter(({id}) => (currentArticle.editions.includes(id)))),
        tags: tagFormat(railsTags.filter(({id}) => (currentArticle.tags.includes(id)))),
        lastSave: currentArticle.lastSave,
        publicUrl: currentArticle.publicUrl,
        slideImages: currentArticle.slideImages,
      });
    }
  }, [])

  const publishDateTime = new Date(currentArticle?.publishDate);

  return (
    <div className='article-form'>
      <div className='article-form__header'>
        <div className='header__left-side'>
          <p
            className='header__article-type'
            onClick={ removeArticleCard }
          >{ articleType.name }</p>
          <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 4L0 0H8L4 4Z" fill="#A0A3BD"/>
          </svg>

        </div>
        {publishDateTime > new Date() && (
          <p class="schedule-notice">
            Artículo se publicará
            {" "}
            <span>
              {publishDateTime.toLocaleString('es-MX', {day: '2-digit'})}
              {' '}
              {publishDateTime.toLocaleString('es-MX', {month: 'short'})}
              {' | '}
              {publishDateTime.toLocaleString('es-MX', {hour: '2-digit', minute: "numeric"})}
            </span>
          </p>
        )}
        <div className='header__right-side'>
          <select
            className='form-select'
            onChange={ ({target}) => handleEditForm('status', target.value) }
            value={ articleParams.status }
          >
            <option>Elegir status</option>
            { 
              Object.keys(statuses).map((value, index) => (
                <option value={value} key={index}>{value}</option>
              ))
            }
          </select>

          <button disabled={!allowSave} className='btn btn-publish mx-2' onClick={() => formRef.current.submit()}>Guardar</button>
          {
            currentArticle?.id &&
              <PopUpDelete 
                button={'<button class="btn btn-publish">Eliminar</button>'}
                text='Estas seguro de eliminar este articulo'
                url={ pathNames.deleteArticle }
              />
          }
        </div>
      </div>
      <main className="article-form__wrapper mt-5">
        <div className="article-form__body">
          <input
            type="text"
            className="input-title"
            placeholder="Añadir titulo del artículo"
            onChange={ ({target}) => handleEditForm('title', target.value) }
            value={ articleParams.title }
          />

          <textarea
            className="input-description"
            placeholder='Añade la descripción de tu articulo en 2 o 3 lineas'
            onChange={ ({target}) => handleEditForm('description', target.value) }
            value={ articleParams.description }
          ></textarea>
          
          <div className="input-preview">
            <PreviewImageImprove accept="image/png, image/webp, image/jpeg, image/jpg" initialImage={ currentArticle?.img } inputData={ 'article '} onChangeImage={ editImage } />
            <p className='input-preview__text'>
              *Recomendado usar imagenes mayores de 1200px de ancho
              <br />
              *No subir imagenes mayores a 10MB
            </p>
          </div>
          <input
            type='text'
            className="input-image-description mb-4"
            placeholder='Añadir caption o el origen de la fuente de la imagen'
            onChange={ ({target}) => handleEditForm('imgDescription', target.value) }
            value={ articleParams.imgDescription }
          />
          {
            (articleParams.articleType.name == 'Video' && articleParams.id) &&
              <PreviewVideo fileUrl={currentArticle?.video} recordId={articleParams.id} />
          }

          <form method='post' encType="multipart/form-data" autoComplete="off" ref={formRef} action={ pathNames.formAction }>
            <CarruselForm showCarrusel={showCarruselDrop} images={articleParams.slideImages} />

            {
              currentArticle?.id &&
                <input type="hidden" name="_method" value="PUT" />
            }

            <input type='hidden' name='article[title]' value={ articleParams.title } />
            <input type='hidden' name='article[description]' value={ articleParams.description } />
            <input type='file' className='d-none' name='article[main_image]' ref={ imageRef } />
            <input type='file' className='d-none' name='article[video]' ref={ videoRef } />
            <input type='hidden' name='article[image_description]' value={ articleParams.imgDescription } />
            <input type='hidden' name='article[content]' value={ articleParams.content } />
            <input type='hidden' name='article[article_type_id]' value={ articleParams.articleType.id } />

            {
              articleParams.tags.map(tag => (
                <input type='hidden' name='article[tag_ids][]' value={ tag.value } />
              ))
            }
            {
              articleParams.categories.map(ctgy => (
                <input type='hidden' name='article[category_ids][]' value={ ctgy.value } />
              ))
            }
            {
              articleParams.editions.map(ctgy => (
                <input type='hidden' name='article[edition_ids][]' value={ ctgy.value } />
              ))
            }
            {
              articleParams.slug &&
              <input type='hidden' name='article[slug]' value={ articleParams.slug } />
            }
            
            <input type='hidden' name='article[score_box]' value={ JSON.stringify(articleParams.scoreBox) } />
            <input type='hidden' name='article[status]' value={ articleParams.status } />
            <input type='hidden' name='article[category_id]' value={ articleParams.category_id } />
            <input type='hidden' name='article[publish_date]' value={ articleParams.publishDate } />
            <input type='hidden' name='article[user_id]' value={ articleParams.user_id } />
            <input type="hidden" name="authenticity_token" value={ authenticityToken } />
          </form>

          <div className="my-3 text-center">
            Añade el body text de tu artículo. Se recomienda adicionar a tu articulo titulos con Heading 2, a su vez enlaces y negritas para hacer mas facil la lectura
          </div>
          <Editor
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={ currentArticle?.content }
            onChange={ ({target}) => handleEditForm('content', target.getContent()) }
            remove_script_host={ false }
            convert_urls={ false }
            relative_urls={ false }
            init={{
              height: 500,
              images_upload_url: pathNames.tinyImage,
              menubar: false,
              automatic_uploads: true,
              file_picker_types: 'image',
              images_file_types: "jpg,png,jpeg,webp",
              block_unsupported_drop: false,
              convert_urls: false,
              relative_urls: false,
              language: 'es_MX',
              image_caption: true,
              plugins: `
                advlist hr autolink lists link image charmap print preview anchor
                searchreplace visualblocks media code fullscreen
                insertdatetime media table paste code emoticons wordcount
                searchreplace visualblocks code image
              `,
              toolbar: 'fullscreen blocks | bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify |' + 
              'bullist numlist outdent indent hr | removeformat  | emoticons | image | link + undo redo code',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          />
        </div>
        <ArticleFormTools tags={tags} categories={categories} editions={editions} setShowCarrusel={setShowCarruselDrop} handleEditForm={ handleEditForm } articleParams={ articleParams } />
      </main>
    </div>
  )
}

ArticleForm.propTypes = {}

export default ArticleForm;
