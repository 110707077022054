import React from 'react'
import PropTypes from 'prop-types'

const SpecificFilterItem = ({ name, removeSubFilter }) => {
    const handleRemoveName = () => {
        removeSubFilter(name);
    }

    return (
        <li className="list-group-item d-flex justify-content-between">{ name } 
            <svg onClick={ handleRemoveName } className="cursor-pointer" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.00072 5.58672L11.9507 0.636719L13.3647 2.05072L8.41472 7.00072L13.3647 11.9507L11.9507 13.3647L7.00072 8.41472L2.05072 13.3647L0.636719 11.9507L5.58672 7.00072L0.636719 2.05072L2.05072 0.636719L7.00072 5.58672Z" fill="#DB3D91"/>
            </svg>
        </li>
    );
}

SpecificFilterItem.propTypes = {

}

export default SpecificFilterItem
