import React from "react";
import PropTypes from "prop-types";

const EventsPriceItem = ({
  event,
  index,
  removeElementByIndex,
  isRemoveEnable,
  changeElementList,
}) => {
  const { price, name } = event;

  const handleRemoveElement = (e) => {
    e.preventDefault();

    removeElementByIndex(index);
  };

  const handleElementListChange = (index, name, value) => {
    changeElementList(index, name, value);
  };

  return (
    <div className="row">
      <div className="col-5">
        <div className="input-group mb-3">
          <span className="input-group-text">$</span>
          <input
            type="number"
            step="0.00"
            className="form-control"
            min="0.00"
            placeholder="00.00 MXM"
            defaultValue={price}
            onChange={({ target }) =>
              handleElementListChange(index, "price", target.value)
            }
          />
        </div>
      </div>
      <div className="col-6">
        <input
          className="form-control"
          placeholder="Ejm: VIP"
          type="text"
          defaultValue={name}
          onChange={({ target }) =>
            handleElementListChange(index, "name", target.value)
          }
        />
      </div>
      <div className="col-1">
        {isRemoveEnable && (
          <button
            className="btn btn-danger ml-2 text-white"
            onClick={handleRemoveElement}
          >
            x
          </button>
        )}
      </div>
    </div>
  );
};

EventsPriceItem.propTypes = {};

export default EventsPriceItem;
