import React, { useState, useEffect } from "react";
import PopUp from "../layout/PopUp";
import PreviewImageImprove from "./PreviewImageImprove";
import SearchSelect from "./SearchSelect";

const pictureIcon = (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.828 18L2.808 18.02L2.787 18H0.992C0.728813 17.9997 0.476497 17.895 0.290489 17.7088C0.104482 17.5226 -1.33455e-07 17.2702 0 17.007V0.993C0.00183004 0.730378 0.1069 0.479017 0.292513 0.293218C0.478126 0.107418 0.72938 0.00209465 0.992 0H19.008C19.556 0 20 0.445 20 0.993V17.007C19.9982 17.2696 19.8931 17.521 19.7075 17.7068C19.5219 17.8926 19.2706 17.9979 19.008 18H2.828ZM18 12V2H2V16L12 6L18 12ZM18 14.828L12 8.828L4.828 16H18V14.828ZM6 8C5.46957 8 4.96086 7.78929 4.58579 7.41421C4.21071 7.03914 4 6.53043 4 6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4C6.53043 4 7.03914 4.21071 7.41421 4.58579C7.78929 4.96086 8 5.46957 8 6C8 6.53043 7.78929 7.03914 7.41421 7.41421C7.03914 7.78929 6.53043 8 6 8Z"
      fill="#DB3D91"
    />
  </svg>
);

const PopUpEventSliceshowForm = ({ url, authenticityToken, railsInputs }) => {
  const [showPopUp, setShowPopUp] = useState(false);
  const [events, setEvents] = useState([]);

  const closePopUp = () => {
    setShowPopUp(false);
  };

  const handleClosePopUp = (e) => {
    e.preventDefault();
    closePopUp();
  };

  const handleOpenPopUp = (e) => {
    e.preventDefault();
    setShowPopUp(true);
  };

  useEffect(() => {
    if (!showPopUp) return;

    const getEvents = async () => {
      const res = await fetch("event_slideshows/events");
      const data = await res.json();

      setEvents(data.events);
    };

    getEvents();
  }, [showPopUp]);

  return (
    <>
      <button
        onClick={handleOpenPopUp}
        className="btn bg-pink text-danger px-3"
      >
        <strong>
          <span className="px-2">{pictureIcon} Agregar evento</span>
        </strong>
      </button>
      {showPopUp && (
        <PopUp popUpClass="--confirm-commerce" closeAction={closePopUp}>
          <div className="popup__header">
            <div>Agregar evento</div>
            <div className="popup__header__close">
              <button
                className="popup__header__close__button --red-button"
                onClick={() => setShowPopUp(false)}
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.00072 5.58672L11.9507 0.636719L13.3647 2.05072L8.41472 7.00072L13.3647 11.9507L11.9507 13.3647L7.00072 8.41472L2.05072 13.3647L0.636719 11.9507L5.58672 7.00072L0.636719 2.05072L2.05072 0.636719L7.00072 5.58672Z"
                    fill="#021D49"
                  />
                </svg>
              </button>
            </div>
          </div>
          <form
            method="post"
            enctype="multipart/form-data"
            autocomplete="off"
            action={url}
          >
            <div className="popup__body text-start p-3 py-4">
              <input
                type="hidden"
                name="authenticity_token"
                value={authenticityToken}
              />
              <div className="mb-3">
                <label className="form-label">Evento</label>
                <SearchSelect
                  options={events}
                  values={[]}
                  props={{
                    placeholder: "Seleccionar evento",
                    name: "event_slideshow[event_id]",
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Vista previa</label>
                <PreviewImageImprove
                  initialImage={railsInputs.image}
                  aditionalClassName="--no-z"
                  inputData={{
                    name: "image",
                    model: "event_slideshow",
                  }}
                />
                <span>Tamaño recomendado 1586x582px</span>
              </div>

              <div className="popup__footer flex-row-reverse">
                <button type="submit" className="btn btn-publish mx-3">
                  Agregar
                </button>
                <button className="btn btn-return" onClick={handleClosePopUp}>
                  Cancelar
                </button>
              </div>
            </div>
          </form>
        </PopUp>
      )}
    </>
  );
};

export default PopUpEventSliceshowForm;
